//import "mutationobserver-shim";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//import Vuetify from "vuetify";
import vuetify from "./plugins/vuetify"; // **
import "@babel/polyfill";
import VueCookies from "vue-cookies";
//** */
//import { TiptapVuetifyPlugin } from "tiptap-vuetify";
// don't forget to import CSS styles
import "./plugins/bootstrap-vue";

Vue.use(require("vue-script2"));
import VueSocialSharing from "vue-social-sharing";
import VueMeta from "vue-meta";

Vue.use(VueSocialSharing);
//import "tiptap-vuetify/dist/main.css";
// Vuetify's CSS styles
//import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";

import "./registerServiceWorker";
//import 'material-design-icons-iconfont/dist/material-design-icons.css'
//const vuetify = new Vuetify()

//Vue.use(Vuetify);

// use this package's plugin
/*Vue.use(TiptapVuetifyPlugin, {
    // the next line is important! You need to provide the Vuetify Object to this place.
    vuetify, // same as "vuetify: vuetify"
    // optional, default to 'md' (default vuetify icons before v2.0.0)
    iconsGroup: "mdi",
});  */

Vue.use(VueCookies);
Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true,
});
Vue.config.productionTip = false;

new Vue({
    router,
    vuetify,
    store,
    render: h => h(App),
}).$mount("#app");
