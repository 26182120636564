import axios from "axios";
export const getAllUsers = ({ commit }) => {
    // console.log('mrk')
    fetch(process.env.VUE_APP_URL + "/users_search_paginated", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
            limit: 50,
            skip: 0,
        }),
    })
        .then(response => response.json())
        // .then(data => console.log("search data", data))

        .then(data => {
            commit("allUsers", data);
        });
};
export const getChallenges = ({ commit }) => {
    fetch(process.env.VUE_APP_URL + "challenge", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    })
        .then(response => response.json())
        //.then(data => console.log("my_challenges", data))

        .then(data => {
            commit("myChallengesData", data);
        });
};
export const getBoostTour = ({ commit }) => {
    fetch(process.env.VUE_APP_URL + "/tournament_boosted_v2", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    })
        .then(response => response.json())
        // .then(data => console.log("boost data", data))

        .then(data => {
            commit("boostedTour", data);
        });
};
export const getAllFlags = ({ commit }) => {
    axios
        .get(process.env.VUE_APP_URL + "/countries", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
        .then(response => {
            commit("setFlags", response.data);
        });
};
export const getMessageInfo = ({ commit }) => {
    axios
        .get(process.env.VUE_APP_URL + "/message", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
        .then(response => {
            commit("SET_NEW_MESSAGES", response.data);
        });
};

/*(export const getNotification = ({ commit }) => {
    axios
        .get(process.env.VUE_APP_URL + "/notifications", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
        .then(response => {
            commit("setNotification", response.data);
        });
};

export const getMessage = ({ commit }, id) => {
    axios
        .get(process.env.VUE_APP_URL + `/message/${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
        .then(response => {
            commit("setMessage", response.data);
        });
}; 
export const getAllUsers = ({ commit }) => {
    axios
        .get(process.env.VUE_APP_URL + "/users_search", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
                titule: "",
                years_min: "",
                years_max: "",
                rating_standard_min: "",
                rating_standard_max: "",
                city: "",
                sex: "",
                open2new_eng_club: "",
                open2new_eng_tournament: "",
                open2new_eng_event: "",
                federation: "",
                search_text: "",
            }),
        })

        .then(response => {
            commit("allUsers", response.data);
        });
};*/
