import Vue from "vue";
import Vuex from "vuex";
// import createWebSocketPlugin from './websocketStorePlugin'
// import socket from '../socket'
//import notification from './modules/notification';

Vue.use(Vuex);

// const websocketPlugin = createWebSocketPlugin(socket)

import state from "./state";
import * as mutations from "./mutations";
import * as actions from "./actions";

export default new Vuex.Store({
    state, //    getters,
    mutations,
    actions,
    modules: {
        //notification
    },
    // plugins: [websocketPlugin]
});
