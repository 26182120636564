<template>
    <div id="app">
        <div id="nav">
            <router-link to="/" />
        </div>
        <!--  <transition name="fade" mode="out-in">-->
        <router-view :key="$route.fullPath" />
        <!--   </transition>-->
    </div>
</template>

<script type="module"></script>

<script>
//import { version } from "../package.json";

/*
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCQUZt6zFdh4abTOU6NuOk2gOsyOIBm90s",
    authDomain: "outpostchess-572a5.firebaseapp.com",
    projectId: "outpostchess-572a5",
    storageBucket: "outpostchess-572a5.appspot.com",
    messagingSenderId: "955489373658",
    appId: "1:955489373658:web:7fb0863b811f46e51844e3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Cloud Messaging and get a reference to the service
//const messaging = firebase.messaging.isSupported()? firebase.messaging() : null;
//const messaging = firebase.messaging.isSupported()? getMessaging(app) : null;
const messaging = getMessaging(app);

onMessage(messaging, payload => {
    console.log("Message received. ", payload);
    // alert("ide regular push");
});
const vapidKey = "BCafzu2wmzd5X7D_SfGiMO4NAVUr4fqbbnvlPloEHqfna3c01Ngjjrs1PbwjvD4dzyS853m-7FlpWHzinBykYnk";
getToken(messaging, { vapidKey })
    .then(currentToken => {
        if (currentToken) {
            localStorage.setItem("tok_fire", currentToken);
            //  console.log("currentToken", localStorage.getItem("tok_fire"));
            fetch(process.env.VUE_APP_URL + "/firebase_registration_token", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: JSON.stringify({
                    token: currentToken,
                }),
            });
        } else {
            // Show permission request UI
            console.log("No registration token available. Request permission to generate one.");
            // ...
        }
    })
    .catch(err => {
        console.log("An error occurred while retrieving token. ", err);
        // ...
    });
*/
export default {
    name: "App",
    data: () => ({
        user: [],
        messages_contact_alert: [],
        notification_array: [],
        fullname: String,
        email: String,
        backendThinkVersion: "",
        my_connection: [],
    }),
    mounted() {
        //localStorage.setItem("socket", 0);
        //get version of front
        /*  fetch(process.env.VUE_APP_URL + "/frontend_version", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
            .then(response => response.json())
            .then(data => (this.backendThinkVersion = data))
            // .then(data => console.log("backendThinkVersion", data))
            .then(this.checkVersion);  */
        //  console.log('package.json',version);

        if (localStorage.getItem("token")) {
            this.getUser();

            fetch(process.env.VUE_APP_URL + "/message", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
                .then(response => response.json())
                .then(data => (this.messages_contact_alert = data))

                .then(this.setMessageInfo);

            /*   fetch(process.env.VUE_APP_URL + "/notifications", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
                .then(response => response.json())
                .then(data => (this.notification_array = data))
                .then(this.setNotificationArray);  */
        } else {
            this.setUserData("noLogedIn");
        }
    },
    methods: {
        /*  checkVersion() {
            //console.log("1", this.backendThinkVersion.version);
            //console.log("2", version);
            if (version !== this.backendThinkVersion.version) {
                fetch(process.env.VUE_APP_URL + "/frontend_version", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                    body: JSON.stringify({
                        version: "0.1.10",
                    }),
                });
                location.reload(true);
            }
        }, */
        getUser() {
            fetch(process.env.VUE_APP_URL + "current_user_info", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
                .then(response => response.json())
                .then(data => (this.user = data))
                // .then(data => console.log("app data", data))
                .then(this.setUserData);
            setTimeout(() => {
                this.connected();
            }, 500);
            setTimeout(() => {
                this.provera();
            }, 10000);
            // console.log("provre", this.user);
        },
        provera() {
            if (this.user.length == 0) {
                this.signOut();
            }
        },
        signOut() {
            localStorage.removeItem("to_event_from");
            localStorage.removeItem("to_event_from_org");
            localStorage.removeItem("tok_fire");
            localStorage.removeItem("token");
            localStorage.removeItem("id");
            localStorage.removeItem("H");
            localStorage.removeItem("tourUuid");
            window.Intercom("boot", {
                api_base: "https://api-iam.intercom.io",
                app_id: "qfb2wowv",
                name: "",
                email: null, // Email address
            });
            this.$store.commit("SET_MY_FEEDS", 0);
            this.$store.commit("ERASE_STATES", 1);
            this.goToLogin();
        },
        goToLogin() {
            this.$router.push("/login");
        },
        connected() {
            fetch(process.env.VUE_APP_URL + "/connection_list", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
                .then(response => response.json())
                .then(data => (this.my_connection = data))
                // .then(data => console.log("connection list", data))
                .then(this.connectionStore);
        },
        connectionStore() {
            this.$store.commit("MY_CONNECTION_LIST", this.my_connection);
        },
        async setUserData(e) {
            if (e == "noLogedIn") {
                this.fullname = "";
                this.email = null;
            } else {
                this.fullname = this.user.name_first + ` ` + this.user.name_last;
                this.email = this.user.email;
            }

            window.Intercom("boot", {
                api_base: "https://api-iam.intercom.io",
                app_id: "qfb2wowv",
                name: this.fullname, // Full name
                email: this.email, // Email address,
                user_hash: localStorage.getItem("H"),
            });

            this.$store.commit("SET_USER_CURRENT", this.user);

            /*      if (this.$store.state.tournaments == "" && localStorage.getItem("token")) {
                setTimeout(() => {
                    fetch(process.env.VUE_APP_URL + "/tournamentv2", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                        .then(response => response.json())
                        .then(data => (this.listOfTournaments2 = data))
                        // .then(data => console.log("app data", data))
                        .then(this.storeTournament);
                }, 100);
            }*/
        },
        storeTournament() {
            // this.$store.commit("SET_TOURNAMENTS", this.listOfTournaments2);
        },
        setMessageInfo() {
            this.$store.commit("SET_MESSAGE_INFO", this.messages_contact_alert);
        },
        // setNotificationArray() {
        //     this.$store.commit("SET_NOTIFICATION_ARRAY", this.notification_array);
        // },
    },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,400;0,500;0,600;0,700;1,500&display=swap");
</style>
<style>
#app {
    font-family: "Red Hat Display", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
::-webkit-scrollbar {
    display: none;
}
input {
    -moz-appearance: textfield;
}
* {
    transition: 0.3s;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    letter-spacing: 0.2px !important;
}

html,
body {
    min-height: 100vh;
    background-color: #171819;
}

body {
    margin: 0;
    padding: 0;
}
p {
    margin-bottom: 0px;
}
.bgc-brown {
    background-color: #c8a07d;
}
.color-green {
    color: #42b983;
}
.color-brown {
    color: #c8a07d !important;
}
.color-black {
    color: #171819 !important;
}
.color-gray {
    color: #6f7381 !important;
}
.color-gray2 {
    color: #5c5e64 !important;
}
.color-white2 {
    color: #ceceda !important;
}
.color-white {
    color: rgba(255, 255, 255, 1) !important;
}
.color-red {
    color: #f2358d;
}
.color-blue {
    color: #11c6d1 !important;
}
a {
    text-decoration: none;
    color: #ceceda;
}
.mid5-padd,
#middle5-right-end,
#middle5-right-startorg,
.middle5-right-startorg {
    border: 1px solid rgba(84, 84, 84, 0.2);
}
.bold {
    font-weight: bold;
}
#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}

.weight700 {
    font-weight: 700;
}
.sm-show {
    display: none;
}
.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-center2 {
    display: flex;

    align-items: center;
}

.flex-center p {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer_mobile {
    display: none;
}

.v-application--wrap {
    min-height: 0vh !important;
}
.max-width {
    width: max-content !important;
}
.opacity_7:hover {
    opacity: 0.7;
    cursor: pointer;
}

input:focus,
textarea:focus,
select:focus {
    outline: none;
}

::webkit-scrollbar {
    display: none;
}
.cursor-default {
    cursor: default !important;
}

@media only screen and (max-width: 1440px) and (min-width: 1025px) {
    .profile {
        grid-template-columns: 12.25rem auto !important;
    }
}
@media screen and (max-width: 1440px) {
    #app,
    html,
    body {
        font-size: 16px;
    }
}

@media screen and (max-width: 768px) {
    #app,
    html,
    body {
        font-size: 12px !important;
    }
}

@media screen and (max-width: 640px) {
    .footer_mobile {
        display: block;
        position: fixed;
        bottom: 0;
        background-color: #171819;
        width: 100%;
        height: 56px;
        z-index: 7;
    }

    .sm-show {
        display: block;
    }
    .sm-hide {
        display: none !important;
    }
}
.opacity0-page {
    opacity: 0;
    transition: 2.55s;
}
.opacity1-page {
    opacity: 1;
    transition: 2.55s;
}
.shadow {
    opacity: 0.5;
}
</style>
